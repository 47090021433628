import Swiper from "swiper"
export default function instagramApi(){


    jQuery.fn.FCInstagram.accessData = {

        // IGQWRPX3dXYWg0V3VYU0t6V05BcmJvVE41U2NHWTJhN3JsR0pFeFMzc1dSc1l3QXAtR3pwandpV3BTME5DU0syYmpGMzdvSFk2RFd3U0xfYjk3amtDMTNoQ0k3THhGWWNrMV90NWRlQ3pFdHF3ZAWh1Sm5JcTlVVjAZD
        accessToken: "IGQWRQOXprOHBuS082ZAlJxWnQwamt4ampwaUdOdy10WUFjb1gxMEZAtTWFELTBUajNRVTFlSm5YTjdGUFd2NnltbkJzT1U1b1BIX3Bkazlwc3BZASGtTMkd3QUhZATmtNR1cxUFlfblpwandyWmlpVzY1bmJPdmNuSjQZD", 
        // Token
    };
    
    $('#instafeed').FCInstagram({
        max: 10, // A number between 1 and 25 of photos to show. Default: 9
        autoplay: false, // Set autoplay video: true/false. Default: false
        complete: function () { // A callback function to execute after the display of the photos.
            console.log('completed'); 

            const instaItems = document.querySelectorAll('#instafeed a')
            const instaWrapper = document.querySelector('#instafeed')

            const htmlSlide = [...instaItems].map(item => {
                return `
                <div  class="swiper-slide">
                        <span style="visibility:hidden">-</span>
                        <div>
                            ${item.outerHTML}    
                            <div class="content">
                            <svg>
                                <use xlink:href="#icon_instagram_white"></use>
                            </svg>
                            <span class="text-caps-2">Ver no instagram</span>
                            </div>
                        </div>
                </div>
                `
            }).join('')

            instaWrapper.innerHTML = `          
            <div class="swiper swiper-acompanhe-nos" style="display: grid; width:100%;">
                <div class="swiper-wrapper">
                ${htmlSlide}
                </div>
            </div>
            `

            const swiperAcompanhe = new Swiper(".swiper-acompanhe-nos", {
                lazy:true,
                slidesPerView: 'auto',
                spaceBetween: 8,
                centeredSlides:true,
                centeredSlidesBounds:true,
               
                // autoplay:{
                //     delay: 3000
                // }
               
            });
        }
    });  

  
}