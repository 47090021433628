import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default function animationScrollTimeLine() {

    gsap.registerPlugin(ScrollTrigger);
    const isMobile = window.matchMedia("(max-width: 767px)").matches;
    function animationTimeLineSection2(){
        let tl = gsap.timeline({defaults:{ opacity: 1, ease: "back",   }});

        tl.to("#matinal-livre  .to-fill-timeline", {
            scrollTrigger:{
                trigger: ".to-fill-timeline",
                start: "bottom 80%",
                // markers:true,
                scrub: 1,
                end: () => `+=${document.querySelector('#matinal-livre').offsetHeight }`,


            },
            height:'100%',
            duration: 2,
            
            ease: "linear",
        })


        gsap.from('.flor-svg-time-line', {
            scrollTrigger:{
                trigger: ".to-fill-timeline",
                start: "top 80%",
                // markers:true,
            
                // scrub: 1,
               

            },
            transformOrigin:  'top',
            ease: "power.in",
            scale: isMobile ? 2 : 5,
        })
        
        const items = document.querySelectorAll('#matinal-livre .item-time-line')
 
        items.forEach((item, i) => {

           let tl = gsap.timeline({
               paused: true,
               defaults:{ opacity: 0, ease: "power",   }
             });
         
                    // Selecting the first child div
            const firstDiv = item.children[0];
             console.log('what si it ', firstDiv.querySelectorAll(' *'))
            // Selecting the second child div
            const secondDiv = item.children[1];
           
         
          
             tl.from( firstDiv.querySelectorAll(' *'), {y: 20,stagger:0.1, })
             .from(secondDiv.querySelectorAll(' *') , {y:  30 ,stagger:0.1, }, "<")


           ScrollTrigger.create({
               // markers: true,
               once: true,
               start: "top 60%",
               // markers: true,
               trigger: item,
             
               onEnter: () => {               
                   tl.play()
               }
             });
          
        })
      
    }

    function secondText(){
        gsap.from('[js-anima-custom-text] > *' ,{
            scrollTrigger:{
                trigger: "[js-anima-custom-text]",
                start: "10% 90%",
            //    markers:true,

            },
            y:30 , stagger: 0.3, opacity:0})
    }
       


 
    secondText()
    animationTimeLineSection2()

}