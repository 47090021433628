export default function () {

	let callback = (entries, observer) => {
		entries.forEach(entry => {
			if (entry.target.id === "video") {
				if (entry.isIntersecting) entry.target.play()
				else entry.target.pause()
			}
		})
	}

	let observer = new IntersectionObserver(callback, {
		root: null,
		rootMargin: '0px',
		threshold: 0.1
	})
	observer.observe(document.querySelector('#video'))
}
