import Swiper from "swiper"
import { find } from "utils/dom"
import animationScrollTimeLine from "../animation/animationScrollTimeLine"
import observerVideo from "../modules/observerVideo";

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

export default function () {
    const NovaLinha = find("[js-page='novalinha']")
    if (!NovaLinha) return


    const swiperFaq = new Swiper(".swiper-faq", {
        slidesPerView: 1.2,
        spaceBetween: 12,

        centeredSlidesBounds: true,
        scrollbar: {
            el: ".swiper-faq .swiper-scrollbar",
            hide: false,
        },
        breakpoints: {
            540: {
                slidesPerView: 2,
                spaceBetween: 32,
            },
            1024: {
                slidesPerView: 3,
                spaceBetween: 40,
            }
        }

    })


    animationScrollTimeLine()




    function faq() {
        const toggleFaq = document.querySelectorAll('#faq .item-faq .toggle-btn-faq')




        if (toggleFaq) {


            toggleFaq.forEach(item => {
                const box_content = item.closest('.item-faq')
                const p_limitado = box_content.querySelector('.p-limitar')
                console.log(p_limitado.clientHeight)
                if (p_limitado.clientHeight > 126) {
                    p_limitado.classList.add('four-lines');

                    item.addEventListener('click', (e) => {
                        const target = e.target.closest('.item-faq')
                        if (!target) return
                        //clientHeight
                        const p = target.querySelector('.p-limitar')

                        p.classList.toggle('four-lines')

                    })
                }
                else {
                    item.classList.add("button-invisible");
                }


            })
        }
    }

    faq()



    Fancybox.bind("[data-fancybox]", {
        // Your custom options
    });

    document.addEventListener('DOMContentLoaded', () => {
        observerVideo()
    })

}