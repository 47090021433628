import axios from 'axios'
import axiosConfig from './modules/axiosConfig'
import PageHome from './pages/Home.js'
import PageNovaLinha from './pages/NovaLinha.js'
import produtosPage from './pages/Produtos.js';
import PageInspiracao from './pages/Inspiracao';
import PageLogin from "./pages/Login";
import PageSingleInspiracao from "./pages/Single_Inspiracao";
import UseMouseOrKeyboard from "./modules/UseMouseOrKeyboard";
import DesignSystem from "./DesignSystem/DesignSystem";
import { register } from 'swiper/element/bundle';
import logs from "./utils/logs";
import ripple from "./modules/ripple";
import formErros from "./modules/formErros";
import animaAoScroll from "./animation/animaAoScroll";
import animateSetup from "./animation/animateSetup";
import instagramApi from './modules/instagramApi';
import instagramCodigoBase from './modules/instagramCodigoBase';
import animationAccordion from './animation/animationAccordion';
import EditorJS from './modules/Editor';
import IconList from './modules/IconList';
import PagePaginationInspiracao from './pages/pagination_inspiracao';
import animaAoScrollSingleInspiracoes from './animation/animationScrollSingleInspiracoes'


// import SvgUse from "./utils/SvgUse";

// Init
GLOBAL.DesignSystem = DesignSystem({})
register() // Swiper Web Component

// Config
axiosConfig({ csrf: GLOBAL.context?.csrf }, axios)
UseMouseOrKeyboard()
ripple()
formErros()
animateSetup()
animaAoScroll()

// Pages
PageHome()
PageNovaLinha()
produtosPage()
PageLogin()?.init()
PageInspiracao()
PageSingleInspiracao()
PagePaginationInspiracao()
animaAoScrollSingleInspiracoes()

// utils logs
logs()


const setas = document.querySelectorAll('.go-to-next')

setas.forEach(seta => {
    if (seta) {
        seta.addEventListener('click', (event) => {
            event.preventDefault();
            const href = seta.getAttribute('href');
            const section = document.querySelector(href);
            console.log(href)
            if (href == '#top') {
                document.body.scrollIntoView({ behavior: 'smooth' });
            } else
                if (section) {
                    console.log('smooth ?')

                    section.scrollIntoView({ behavior: 'smooth' });
                }
        })
    }
})


instagramCodigoBase()
EditorJS()
IconList()

// deixar por ultimo 
instagramApi()