import { find, findAll } from "../utils/dom";

const PagePaginationInspiracao = () => {
    const page = find("[js-page='inspiracoes']");
    if (!page) return;
    const urlParams = new URLSearchParams(window.location.search);


if (urlParams) {
    
    const params = urlParams.toString();
    const links =  document.querySelectorAll('.pagination a')
    const categoria = urlParams.getAll('categoria');
    const categoriaProduto = urlParams.getAll('categoria_produto');

    if (categoria?.length > 0) { 
        if (links) {
            links.forEach(link => {
                let newURL = link.getAttribute('href').replace('?', '&');

                if (categoria?.length > 0) {
                    newURL += `&categoria=${categoria.join('&categoria=')}`;
                }
                
                link.href = `?${newURL}`;
            });
        }

    }

    if (categoriaProduto?.length > 0) { 
        if (links) {
            links.forEach(link => {
                let newURL = link.getAttribute('href').replace('?', '&');

                if (categoriaProduto?.length > 0) {
                    newURL += `&categoria_produto=${categoriaProduto.join('&categoria_produto=')}`;
                }
                
                link.href = `?${newURL}`;
            });
        }

    }
      
       
    const pagination = document.querySelectorAll('.pagination')
    if(pagination){
        pagination.forEach(pag => {
            pag.style.pointerEvents = 'all';
            pag.style.opacity = 1;
        })
    }
}

}

export default PagePaginationInspiracao;