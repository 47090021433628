import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";


export default function animationAccordion(){
    let groups = gsap.utils.toArray(".accordion-group");
    let menus = gsap.utils.toArray(".accordion-menu");
    let menuToggles = groups.map(createAnimation);
    const isMobile = window.matchMedia('(max-width: 767px)').matches

  
        menus.forEach(menu => {
        
            menu.addEventListener("click", (e) => {
                const target = e.target
                if(  target.closest('.accordion-group').classList.contains('active')){ return}
                menus.forEach(i => i.closest('.accordion-group').classList.remove('active'))
              
                target.closest('.accordion-group').classList.add('active')
                if(!isMobile){
                toggleMenu(menu)
                }
            });
       });
  
   


    function createAnimation(element) {
        
        let menu = element.querySelector(".accordion-menu");
        let box  = element.querySelector(".accordion-content");
        let img  = element.querySelector(".accordion-content img");
        
        gsap.set(box, { width: 'auto',  });
        // gsap.set(img, { scale: 1 ,opacity: 1 });
        // , height: 'auto',
        box.classList.remove('animou-finish')

   
        let animation = gsap.from(box, { 
           width: 0, 
            // height: 0,
          
            duration: 0.5, 
            ease: "power1.inOut",
            onComplete: function() {
                console.log(this)
                // gsap.from(box.querySelector('div'), { opacity: 1,})
                // gsap.from(box, { opacity:1}).reverse()
                box.classList.add('animou-finish')

                // then only replace with blue div with new height and width
              }
        }).reverse();

        //   const imgAnimation =   gsap.from(img , {
        //         opacity: 1

        //     })
        
        return function(clickedMenu) {
            
            if (clickedMenu === menu) {
            animation.reversed(!animation.reversed());
            // imgAnimation.reversed(!imgAnimation.reversed())
            } else {
            animation.reverse();
            // imgAnimation.reverse()
            }
        }
    }

    
    function toggleMenu(clickedMenu) {    
     menuToggles.forEach(toggleFn => toggleFn(clickedMenu))
    }



   const goLeft = document.querySelector('.accordion-produto-left');
   const goRight = document.querySelector('.accordion-produto-right');
   
   if(goLeft){
    goLeft.addEventListener('click', () => {
   
        moveAccordionContent('left');
    });
   }
 
   if(goRight){
    goRight.addEventListener('click', () => {
    
        moveAccordionContent('right');
    });
   }

   let newIndex;
   function moveAccordionContent(direction) {
    //    const activeGroup = document.querySelector('.accordion-group.active');
       const allGroups = document.querySelectorAll('.accordion-group');
        const indexAtivo = document.querySelector('.accordion-group.active')  
        const currentIndex = +indexAtivo.dataset.index
       
    
        

            goLeft.classList.remove('disabled')
        goRight.classList.remove('disabled')

       if (direction === 'left') {
           newIndex = currentIndex - 1;
           if (newIndex <= 1) {
               newIndex = 1 // Wrap around to the last element
               goLeft.classList.add('disabled')
           }
       } else if (direction === 'right') {
           newIndex = currentIndex + 1;
           if (newIndex >= allGroups.length) {
               newIndex = allGroups.length; // Wrap around to the first element
               goRight.classList.add('disabled')
           }
       }

       const itemToClick = document.querySelector(`.accordion-group[data-index="${newIndex}"] .accordion-menu`)
       console.log(itemToClick)
       itemToClick.click()
       console.log(newIndex)
   
     
   }

  

   if(!isMobile){
       toggleMenu(menus[0])
   }
}