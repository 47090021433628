import { gsap } from "gsap";

export default function animationCustomHome() {
    function banner() {
        const tl = gsap.timeline()

        tl.from('.img-banner', { scale: 1.2, duration: 2 })
            .from('.logo-animada svg path', {
                y: 140,
                opacity: 0,
                ease: 'back',
                duration: 1,
                stagger: 0.1
            }, '<')
    }


    banner()

}