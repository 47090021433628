import { findAll } from "../utils/dom";

const IconList = () =>{
    const Icon_1 = `<svg class="w-32 h-32 -translate-y-4" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20 13.5L15 18.5L12 15.5" stroke="#8E7B43" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
</svg>`

    const JS_Icon = findAll('[js-icon-list]');
    if(!JS_Icon) return;

    JS_Icon.forEach((icon)=>{
        const Lis = icon.querySelectorAll('ul li');
        Lis.forEach((Li)=>{
            Li.insertAdjacentHTML('afterbegin', Icon_1);
        })
        //console.log(Lis);
    })
}

export default IconList;