import { find, findAll } from "../utils/dom";
import Swiper from "swiper";
import animationAccordion from "../animation/animationAccordion"
import animationCustomHome from "../animation/animationCustomHome"
import animationCustomProdutos from "../animation/animationCustomProdutos"

const PageInspiracao = () => {
    const page = find("[js-page='inspiracoes']");
    if (!page) return;


    function bt_filtro() {
        const BTFiltro = find('[js-filtro-butao]');
        const FiltroContent = find('[js-filtro-content]');

        if (!BTFiltro) return;

        BTFiltro?.addEventListener("click", () => {
            FiltroContent.classList.toggle('filtro_active');
        })
    }


    function bt_tags() {
        const buttons = findAll('.swiper-button-disabled');
        if (!buttons) return;

        if (buttons.length == 2) {
            find('.swiper-inspiracao-controlls').style.display = "none";
        }
    }


    const swiperinspiracao = new Swiper(".swiper-inspiracao", {
        mousewheel: false,
        slidesPerView: "auto",
        spaceBetween: 20,
        pagination: {
            el: ".swiper-inspiracao-pagination",
            clickable: true,
        },
        navigation: {
            nextEl: ".swiper-inspiracao-next",
            prevEl: ".swiper-inspiracao-prev",
        },
        breakpoints: {
            767: {
                spaceBetween: 80,
            }
        }
        // thumbs: {
        // 	swiper: swiperVerticalTimeline,
        //   },

    })

    animationAccordion();
    animationCustomProdutos();
    bt_tags();
    bt_filtro();
}

export default PageInspiracao;