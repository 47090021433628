import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { find, findAll } from "utils/dom"

export default function animationScrollSingleInspiracoes() {
    const singleInspiracoes = find("[js-page='single_inspiracoes']")
    if (!singleInspiracoes) return

    gsap.registerPlugin(ScrollTrigger);
    const isMobile = window.matchMedia("(max-width: 767px)").matches;
    function animationTimeLineSection2(){
        let tl = gsap.timeline({defaults:{ opacity: 1, ease: "back",   }});

        tl.to("#grid-single-inspiracoes  .to-fill-timeline", {
            scrollTrigger:{
                trigger: ".to-fill-timeline",
                start: "center 50%",
                //markers:true,
                scrub: 1,
                end: () => `+=${document.querySelector('#grid-single-inspiracoes').offsetHeight - 550 }`,
                
            },
            height:'100%',
            duration: 2,
            
            ease: "linear",
        })
    }

    animationTimeLineSection2()

}