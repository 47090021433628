import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default function animationCustomHome(){
    const isMobile = window.matchMedia("(max-width: 767px)").matches;
    const isTablet = window.matchMedia("(max-width: 1024px)").matches;
    function floatingElements(){
        const elements = document.querySelectorAll('.floating-element');

        elements.forEach((el, index) => {
            setTimeout(() => {
              const animation = gsap.to(el, {
                y: 10,
                duration: 2,
                yoyo: true,
                repeat: -1,
                ease: 'none',
              });
            }, 100 * index);
          });

    }


    function banner(){
        const tl = gsap.timeline()

        tl.from('.img-banner', { scale:1.2, duration:2})
        .from('.logo-animada svg path', {
            y:140,
            opacity:0,
            ease: 'back',
            duration: 1,
            stagger: 0.1
        }, '<')
    }

    function quemsomos(){
        
     
        gsap.from('#quem-somos .quem-somos-secundaria', {
            scrollTrigger:{
                trigger: "#quem-somos .quem-somos-secundaria", 
                start: 'top-=100px 30%',          
            
                scrub: 1,
            },

            transformOrigin: 'center',
            y: -200
        })
    }
    function embalagens(){
        
        gsap.from('#embalagens', {
            scrollTrigger:{
                trigger: "#embalagens", 
                start: "top+=100 80%",
               
               
            },
            y: 30,
            opacity: 0,
        })
    }
    function quemEscolhe(){

        // quemn-escolhe
        gsap.set('#quem-escolhe h2',{clipPath: 'inset( 0 100% 0 0)', opacity:0, y:20})
        gsap.to('#quem-escolhe h2', {
            scrollTrigger:{
                trigger: "#quem-escolhe h2", 
                start: "top 80%",
               
               
            },
            y: 0,
            duration: 1,
            clipPath: 'inset( 0 0% 0 0)',
            opacity: 1,
        })
    }

    function passaros(){
        const tl = gsap.timeline({
            scrollTrigger:{
                trigger: "#services-custom .container",
                start: "top 80%",
                // markers:true,
                // scrub: 1,
            }
        })
        // clipPath: 'circle(100%)'
        // gsap.set('.img-wrapper-passaros img',{  clipPath: 'circle(100%)'})
        const stickyImg = document.querySelector(".img-wrapper-passaros");
        gsap.from('.img-wrapper-passaros', {
            scrollTrigger:{
                trigger: ".img-wrapper-passaros",
               
                pin: true,
                pinSpacing: false,
                start: "top 120px",
          
                end: 'bottom top+=' + (stickyImg.clientHeight - 120),
                // endTrigger: "#services-custom"
                // markers:true,
                // scrub: 1,
            }
        })

   
        gsap.set('.img-wrapper-passaros img',{clipPath: 'circle(100%)'})

        tl
        .from('.img-wrapper-passaros img',{clipPath: 'circle(10%)' , duration: 1 ,delay:0.5})
        // .from('.img-wrapper-passaros img', { y: 130, opacity:0  , duration:3})
        .from('#services-custom .passaros-animado svg path', { scale: 0.5, opacity:0, stagger: 0.3}, '<+=0.5')
       
       
    }

    // banner()
    quemsomos()
    embalagens()
    quemEscolhe()
    if(!isTablet){
        passaros()
    }
}