import { find, findAll } from "../utils/dom";
import Swiper from "swiper";

const Single_Inspiracao = () => {
    const page = find("[js-page='single_inspiracoes']");
    if (!page) return;

    const Site_Base = `${document.location.href}`;

    function Compartilhe_Whatssap() {
        const BT_Whatssap = findAll('[js-bt-whatssap]');
        if (!BT_Whatssap) return;

        BT_Whatssap?.forEach((bt) => {
            bt.href = `https://api.whatsapp.com/send?text=${encodeURIComponent(Site_Base)}`;

        });
    }

    function Compartilhe_Facebook() {
        const BT_Facebook = findAll('[js-bt-facebook]');
        if (!BT_Facebook) return;

        BT_Facebook?.forEach((bt) => {
            bt.href = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(Site_Base)}`;

        });
    }

    function Compartilhe_X() {
        const BT_X = findAll('[js-bt-x]');
        if (!BT_X) return;

        BT_X?.forEach((bt) => {
            bt.href = `https://twitter.com/intent/tweet?url=${encodeURIComponent(Site_Base)}`;
        })
    }

    function Compartilhe_Twitch() {
        const BT_Twitch = findAll('[js-bt-twitch]');
        if (!BT_Twitch) return;

        BT_Twitch?.forEach((bt) => {
            bt.href = `https://www.twitch.tv/share?url=${encodeURIComponent(Site_Base)}`;
        })
    }

    const swiperprodutosrelacionados = new Swiper(".swiper-produtos-relacionados", {
        slidesPerView: 1,
        spaceBetween: 80,
        pagination: {
            el: ".swiper-produtos-relacionados-pagination",
            clickable: true,
        },
        navigation: {
            nextEl: ".swiper-produtos-relacionados-next",
            prevEl: ".swiper-produtos-relacionados-prev",
        },
        // breakpoints: {
        //     1200: {
        //         slidesPerView: 1.35,
        //     }
        // }
        // thumbs: {
        // 	swiper: swiperVerticalTimeline,
        //   },

    })

    Compartilhe_Facebook();
    Compartilhe_Whatssap();    
}

export default Single_Inspiracao;